import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import isMobile from "is-mobile";
import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";

import { GetLastMinute } from "../api";
import Link from "next/link";
import themes from "../../themes/themes";
import { useTranslation } from "../../context/TranslationContext";

const DesktopView = dynamic(() => import("../LastMinute/DesktopView"));
const MobileView = dynamic(() => import("../LastMinute/MobileView"));

//import { Icon } from "../../icons/icons";

// import Lottie from "lottie-react";

//TODO Son Dakika fırsatlarını bağla.

const LastMinute = () => {
  const { all, showAll, lastMinuteTitle } = useTranslation();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const isPhone = isMobile();

  useEffect(() => {
    GetLastMinute()
      .then((r) => {
        setItems(r.data);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, []);

  const style = {
    height: 64,
    width: 64,
  };
  if (items?.data?.length > 0)
    return (
      <div
        className={`bg-brand-gray-7 py-5 lg:py-14 lg:pt-32 lg:pb-52 overflow-hidden flex ListVilla ${themes.LastMinute.WrapperClass}`}
      >
        <div className="bg-brand-gray-7 flex-auto relative z-10"></div>
        <div className="bg-brand-gray-7 container">
          <div className="flex gap-x-5 flex-col lg:flex-row gap-y-6 lg:gap-y-0 overflow-hidden xl:overflow-visible">
            <div className="flex items-center lg:justify-start lg:items-start lg:flex-col lg:w-1/3 bg-brand-gray-7 z-10 lg:pr-3 xl:pr-10">
              <div className="lg:block">
                {/* <Lottie
                  animationData={JSON.parse(items.animation)}
                  style={style}
                /> */}
              </div>
              <h3
                className="font-bold text-xl  lg:text-3xl text-orange xl:leading-69 w-40 lg:w-auto"
                dangerouslySetInnerHTML={{
                  __html: lastMinuteTitle
                    .replace("{", '<span class="text-black">')
                    .replace("}", "</span>"),
                }}
              ></h3>
              <p className="text-15 text-black opacity-50 pb-8 hidden lg:block">
                {items.kisa_icerik}
              </p>
              <Link href={items.url}>
                <a
                  title={items.title}
                  className="text-brand-black font-bold text-13 lg:hidden ml-auto"
                >
                  {all}{" "}
                  <BsThreeDots
                    size="36"
                    className="inline-block text-brand-blue"
                  ></BsThreeDots>
                </a>
              </Link>
              <Link href={items.url}>
                <a
                  title={items.title}
                  className="bg-primary font-bold text-sm text-white py-5 px-9 hidden lg:block"
                >
                  {showAll}
                </a>
              </Link>
            </div>
            <div className="w-full lg:w-2/3">
              {isPhone ? (
                <MobileView items={items} />
              ) : (
                <DesktopView items={items} />
              )}
            </div>
          </div>
        </div>
        <div className="flex-auto"></div>
      </div>
    );
};

export default LastMinute;
